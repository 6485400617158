

//web Analytics -- Begin
export const enum AnalyticsWebPlatform {
  firebase = 'firebase',
}

export const COUNTRY_CODE = '+91';

export const PAGINATION_LIMIT = 10;
export const MAX_PAGINATION_LIMIT = 1000;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_LANG_CODE = 'en';
export const GUEST_USER_DETAILS_BY_IP_API = 'https://ipapi.co/json';
export const DEBOUNCE_DELAY = 300;
export const ENROLL_COHORT_DELAY = 1000;
export const STATUS_DEBOUNCE_DELAY = 500;

export const enum userRoles {
  student = 'STUDENT',
  faculty = 'FACULTY',
  regionalManager = 'REGIONAL_MANAGER',
  instituteAdmin = 'INSTITUTE_ADMIN',
  expert = 'EXPERT'
};

export const enum ventureEmployeeRoles {
  admin = 'ADMIN',
}

export const MINIMUM_SEARCH_CHAR_LIMIT = 3;

export const enum FileServiceBuckets {
  milestoneTemplateDocs = 'milestone_template_docs',
  milestoneTemplateImages = 'milestone_template_images',
  ventureImages = 'venture_images',
  ventureDocs = 'venture_docs',
  profileImages = 'profile_images',
  chatDocs = 'chat_docs',
  chatImages = 'chat_images'
}

export const enum FileServiceIncludeTypes {
  publicUrl = 'publicUrl',
  sasUrl = 'sasUrl'
}

export const DayType = {
  TODAY: 'Today',
};

export enum AllowPost {
  ALL = "all",
  ADMIN = "admin",
};

export const ConversationType = {
  ONE: "ONE",
  GROUP: "GROUP",
  ANNOUNCEMENT: 'ANNOUNCEMENT',
};

export const CONVERSATIONS_PAGE_LIMIT: number = 10;
export const PAGE_NUMBER = 1;

export const DIRECTION = {
  before: 'before',
  after: 'after',
};

export const CHAT_MESSAGES_LIMIT = 20;
export const NEW_MESSAGES_LIMIT = 1000;

export enum chatMessageType {
  Text = 1,
  Image = 2,
  File = 3,
}

export const chatDocFileType = [
  'application/pdf',
  'application/zip',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const chatImageFileType = {
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
};

export const FILE = 'file';

export const CarouselResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
export enum BatchStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum VentureImagesAspectRatios {
  companyLogo = 2,
}

export const VENTURE_TYPE_IN_PAYLOAD = 'COLLEGE';

export enum APP_MENU_ITEM {
  DASHBOARD = 'dashboard',
  HOME = 'home',
  REPORTS = 'Reports',

  COHORTS = 'cohorts',
  VENTURES = 'ventures',
  CALENDAR = 'calendar',
  LIBRARY = 'library',
  INTERVIEW = 'interview',
  MENTORS = 'mentors'
}

export const enum WebViewMessageType {
  OPEN_PROFILE_SCREEN = "OPEN_PROFILE_SCREEN",
  ERROR_GO_TO_HOME_PAGE = "ERROR_GO_TO_HOME_PAGE",
  OPEN_PDF = "OPEN_PDF",
  OPEN_LINK_NEW_WEBVIEW = "OPEN_LINK_NEW_WEBVIEW",
  PRODUCT_TOUR_SESSION_UPDATE = "PRODUCT_TOUR_SESSION_UPDATE",
  REDIRECT_TO_HOME = "REDIRECT_TO_HOME"
}

export const CLIENT_NAME = 'WO';


export const DEFAULT_LANG = 'English';

export const SUPPORTED_LANGUAGES: { value: string, code: string }[] = [
  {
    value: 'English',
    code: 'en',
  },
  {
    value: 'Portuguese',
    code: 'pt',
  },
  {
    value: 'Hindi',
    code: 'hi',
  }
]

export const DEFAULT_EVENTS_LIMIT = 6;


export const enum AnalyticsWebEvents {
  HOME_SCREEN_VISIT = 'home_screen_visit',
  USER_LOGIN = 'user_login',
  USER_SIGNUP = 'user_signup',
  USER_TYPE = 'userType',
  CERTIFICATE_DOWNLOAD = "certificate_download",
  CERTIFICATE_SHARE = "certificate_share",
  AI_COACH_CHAT = "aicoach_chat",
  AI_COACH_SHORTCUT = "aicoach_shortcut",
  AI_COACH_TOOLKIT_OPEN = "aicoach_toolkit_open",
  PLACEMENT_ANSWER_SUBMIT = "placement_answer_submit",
  INTERVIEWPREP_QUESTION_SELECTED = "interviewprep_question_selected",
  INTERVIEWPREP_JOB_QUESTIONS = "interviewprep_job_questions",
  INTERVIEWPREP_ANSWER_START = "interviewprep_answer_start",
  INTERVIEWPREP_ANSWER_STOP = "interviewprep_answer_stop",
  PLACEMENT_FEEDBACK = "placement_feedback"
}

export const enum FileType {
  IMAGE = "image",
}

export const MAX_RATING = 5;

export const genderList = [
  {
    key: "Male",
    value: "Male",
  },
  {
    key: "Female",
    value: "Female",
  },
  {
    key: "Others",
    value: "Others",
  },
];

export const MIN_AGE_YEARS = 10;
export const MAX_AGE_YEARS = 70;

export const SKILL_SCORE_CARD = 'skillscorecard';

export const ENGLISH_MONTHS_ABBR = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec';

export const SPANISH_MONTHS_ABBR = 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic';

export const PORTUGUESE_MONTH_NAMES = 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro';

export const PORTUGUESE_MONTHS_ABBR = 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez';

export const MEETING_DURATION_LIST = [15, 30, 60, 90];

export const enum ChannelName {
  CHAT = "chat",
  VENTURE = "venture",
  BATCH = "batch",
  CONTENT = "content",
  COURSE = "course"
}

export const enum SuperAdminMessageType {
  SET = "set",
  SET_CONFIRM = "setConfirm",
  DELETE = "delete",
  FIRST_ACKNOWLEDGEMENT = "firstAcknowledgement",
  CLOSE_MODAL = "closeModal",
}

export const enum Visibility{
  PUBLIC="PUBLIC"
};

export const enum ContentKey {
  TOPIC = 'topic',
  TYPE = 'type',
  TECHNOLOGY = "technology",
  SECTOR = "sector"
}

export const OFFICE_RENDER_FILE_URL = "https://view.officeapps.live.com/op/embed.aspx?src=";
export const GOOGLE_RENDER_FILE_URL = "https://docs.google.com/viewer?url="
export const RENDER_GOOGLE_DOCS_PDF = "https://docs.google.com/gview?embedded=true&url="

export const enum AUTH_FLOW {
  SIGN_IN = "signIn",
  SIGN_UP = "signUp"
}

export const enum SOCIAL_MEDIA {
  GOOGLE = "google"
}

export const enum USER_EXIST {
  USER_EXISTED = "userExisted",
  USER_NOT_EXISTED = "userNotExisted"
}

export const GOOGLE_USERINFO_ENDPOINT = "https://www.googleapis.com/oauth2/v3/userinfo?access_token=";

export const enum certifiedCoursesStatus {
  PUBLISHED = "published",
  DRAFTED = "draft",
}

export const AI_COACH_TYPE = {
  aiGpt: "ai-prompt-gpt",
  userInput: "ai-prompt-gpt-input",
  local: "ai-prompt-loca"
}

/*Feature toggle : By default feature state applicable for all
 users and Ored with beta users for beta features specific activation*/
export const FEATURE_TOGGLE = Object.freeze({
  courseCertification: true,
  connectCommunity: true,
  userSettings: true,
  selfServeCourse: false,
  interviewPrep: true,
  aiCoach: true,
  forums: true,
  careers: false,
  library: false,
  freshdesk: true,
  newUiTour: true,
  mentors: true
});


export const ActivityStatus = {
  COMPLETED: "completed",
  COMPLETED_PASS: "completedPass",
  COMPLETED_FAIL: "completedFail",
  IN_PROGRESS: "inProgress",
  NOT_STARTED: "notStarted"
}

export const ActivityType = {
  SCORM: "scorm",
  QUIZ: "quiz",
  PDF: "pdfannotator",
  RESOURCE: "resource",
  FEEDBACK: "feedback",
  PAGE: "page",
  H5: "h5pactivity",
}

export const STUDENT_UPLOAD_TEMPLATE = "https://wfopportunity1.blob.core.windows.net/excel-sheets/WO-Stu-BU-V1.xlsx"

export enum BulkUploadStatus {
  INPROGRESS = 'inProgress',
  COMPLETED = 'completed'
}

export const ScreenOrientation = {
  landscape: 'landscape',
  portrait: 'portrait'
}

export const CookiesKeys = {
  MOODLE_SESSION: 'MoodleSession',
  EXPIRES: 'expires'
}

export enum CourseStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = 'IN_PROGRESS'
}

export const submitSAText = {
  ASSESMENT_SUBMITTED: "assessmentSubmitted"
}
export const NUMBER_CONFETTI_ITEMS = 50;
export const AVG_SCORE_DATA = 70;

export const AI_MESSAGE_TYPE = {
  chatGPT: "chatGPT",
  local: "local"
}

export const AI_COACH_MODEL_WIDTH = {
  Large: "70vw",
  Small: "35vw"
}

export enum TagKey {
  SECTOR = 'sector',
  SECTOR_SKILLS = 'sector-skills',
  COUNTRY_LANGUAGE_MAPPING = 'country-language-mapping',
  INTERVIEW_PREP_LANGUAGE_MAPPING = 'interview-prep-supported-languages',
}

export enum TagKeys {
  SECTOR_SKILLS = 'sector-skills',
  SECTOR = 'sector',
  COURSE_TYPE = 'course-type',
  COURSE_GROUP = 'course-groups',
  COUNTRY_COURSE_LANGUAGES = 'country-course-languages'
}

export enum userStatus {
  DELETED = "DELETED",
  NOT_ENROLLED = "notEnrolled"
}

export enum Scopes {
  INTERVIEW_PREP = 'interview-prep',
  PROFILE = 'profile'
}

export const ANDROID_PLAYSTORE_LINK = "https://play.google.com/store/apps/details";
export const IOS_APPSTORE = "https://apps.apple.com/us/app/apple-store/6456377393";

export enum Platform {
  IOS = "IOS",
  ANDROID = "ANDROID",
  WEB = "WEB"
}

export enum UTM_SOURCE {
  MOBILE_WEB = 'mobile_web',
  DESKTOP_WEB = 'desktop_web'
}

export enum UTM_MEDIUM {
  FLOATING_BUTTON = 'floating_button',
  FOOTER = 'footer'
}

export enum UTM_CAMPAIGN {
  MOBILE_APP_INSTALL = 'mobile_app_install'
}

export const ANDROID_APP_ID = 'com.wadhwani.opportunity';

export enum ServeModes {
  OPEN = 'open',
  BATCH = 'batch'
}

export const enum ThirstPartySSO {
  FRESHWORKS = "freshworks"
}
export const platform = {
  WITP: false,
  WO: true
}

export enum Module {
  COURSE = 'course',
  CONTENT = 'content',
  LLM_RULES = 'llm-rules'
}

export const PRODUCT_NAME = "WO"

export const productTourExpirationDate = new Date();
productTourExpirationDate.setFullYear(productTourExpirationDate.getFullYear() + 10);

export enum EventStatus {
  REGISTERED = 'REGISTERED',
  INVITED = 'INVITED',
  COMPLETED = 'COMPLETED',
  ATTENDED = 'ATTENDED',
  OPEN = 'OPEN',
  NOSHOW = 'NOSHOW',
  ONGOING = 'ONGOING'
}

export enum ActivitiesType {
  MEETING = 'meeting',
  WEBINAR = 'webinar'
}

export const PRODUCT_TOURS = {
  AICOACH: 'ai-coach',
  INTERVIEW_PREP: 'interview-prep',
  DASHBOARD: 'dashboard',
  FORUM: 'forum',
  NEWUITOUR: "new_ui_tour"
}

export const PRODUCT_TOUR_STATUS = {
  completed: 'completed',
  completed_skipped: 'completed_skipped',
}

export const CERTIFICATE_TYPE = {
  MICRO_CERTIFICATE: "Microcertificate",
  PROGRAM_CERTIFICATE: "ProgramCertificate"
}

export enum keysType {
  COMPETENCIES = "competencies",
  TOPIC_KEY = "topicKeys",
  COURSE_KEY = "courseKeys",
  BATCH = "batch"
}

export enum MeetingEnrollStatus {
  ENROLLED = "ENROLLED",
  NOT_ENROLLED = "NOT_ENROLLED"
}

export enum ProgramStatus {
  IN_PROGRESS = "inProgress",
  COMPLETED_PASS = "completed_pass",
  COMPLETED = "completed",
  NOT_STARTED = "notStarted",
  PUBLISHED = 'published'
}

export enum ScopeKeys {
  PROFILE_DEGREE = "profile-degree"
}

export enum OPEN_COURSE_MAPPING {
  WADHWANI = 'wadhwaniCourses',
  INSTITUTE = 'instituteCourses',
  INSTITUTE_AND_WADHWANI = 'instituteAndWadhwaniCourses',
};

export enum PROGRAM_STATUS {
  ENROLLED = 'enrolled',
  NOT_ENROLLED = 'notEnrolled',
};


