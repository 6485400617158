const Auth = {
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    logout: '/logout',
    guest: '/guest',
    enrollCohort: '/enroll-cohort',
    userVerification: '/user-verification/:type/:code/:value',
    authenticate: "/authenticate",

    // typography
    typography: '/typography',

    // WO-CODE-VARIANT
    ssoLogin: '/ssoLogin',
    nsdc: '/nsdc-sso',

    // Profile COmpletion
    updateProfile: '/update-profile'
};

const Interview = {
    interview: '/interview',
    interviewHistory: '/interview/history'
};

const JobPrepare = {
    jobPrepare: '/job-prepare'
};

const Home = {
    default: '/',
    // home: '/dashboard', - we
    // lang: '/:lang(en|es|pt)', - we

    // WO-CODE-VARIANT
    home: '/home',
    lang: '/:lang(en|es|pt|hi|en-US)',
};

const Course = {
    courses: '/courses',
    courseDetails: '/courses/:id',
    connectDiscourse: '/discourse/start',
    courseDetail: "/courses",


    courseLesson: '/courseLesson',
    courseDetailMobile: '/course-mobile',
    courseLmsLesson: '/courses/:courseId/course-lesson/:lessonId/:activityId', // lessonId is section id of lesson and activityId is instance id of activity
    courseLessonMobile: '/course-mobile/:courseId/course-lesson/:lessonId/:activityId',
};

const Forums = {
    forums: '/forums'
};

const Batch = {
    createBatch: '/create-batch',
    batches: '/batches',
    students: '/students',
    cohortDetails: '/batches/details/:id',
    cohortDetail: '/batches/details',
    cohortMembers: '/batches/details/:id/members',
    members: '/members',
    reports: '/reports',
    courseReport: '/reports/profile/:profileId/course/:courseId',
    courseReports: '/reports/profile',
    batchReports: '/batch-reports',
    batchReport: '/batch-reports/:id',
    allBatches: '/allBatches',
    edit: '/batches/edit',
    editBatch: '/batches/edit/:id',
    manageStudent: '/batches/manageStudents',
    manageStudents: '/batches/manageStudents/:batchId',
    inviteStudent: '/batches/inviteStudents',
    inviteStudents: '/batches/inviteStudents/:batchId',
};

const Dashboard = {
  dashboard: "/dashboard",
  guestDashboard: "/guestDashboard",
  support: "/support",
};

const User = {
    user: '/user',
    userProfile: '/user/profile',
};

const General = {
    tnc: '/tnc',
    notFound: '/404',
    refreshCookies: '/refreshCookies',
    openCourses: '/openCourses',
    openCoursesForClient: '/openCourses/:clientId',
};

const Profile = {
    profile: '/user/profile',
    profileDetail: '/user/profile/:userId',
    createProfile: '/user/create',
};

const Privacy = {
    tnc: '/tnc',
};

const PageNotFound = {
    notFound: '/404',
};

const Messages = {
    messages: '/messages',
    messageThread: '/messages/:profileId/:type/:conversationId',
};

const Calendar = {
    calendar: '/calendar',
};

const Components = {
    component: '/component',
};

const Meeting = {
    meeting: '/meeting',
    meetingDetail: '/meeting/:id',
    slotBooking: '/event/slot-booking'
};

const Career = {
    career: '/career-guidance'
};

const Library = {
  contentDetail: '/library/:id',
  library: '/library',
  contentListPage: '/library/list',
  searchPage: '/library/search'
};
const Settings = {
  settings: '/settings',
  settingsCategory: '/settings/category/:menuItem',
  category: '/category',
  linkedSettings: '/:item',
  subMenuSettings: '/settings/:subMenu',
  syncOption: "/settings/:subMenu/:syncOptionKey",
}

const Event = {
    event: '/event',
    eventDetail: '/event/:id'
}

const Program = {
    program: '/program',
    programDetail: '/program/:id'
}

const Mentor = {
    mentors: '/mentors',
    network: '/network',
    mentorListingPage:'/mentors/:ventureId/:type',
    mentor: '/mentor',
    mentorDetails: '/mentor/:userId',
  }

export const Routes = {
    Auth,
    Home,
    Batch,
    Profile,
    Privacy,
    PageNotFound,
    Course,
    Messages,
    Calendar,
    Components,
    Meeting,
    Dashboard,
    General,
    User,
    Settings,
    Interview,
    JobPrepare,
    Forums,
    Career,
    Library,
    Event,
    Program,
    Mentor
};
