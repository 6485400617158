import React from "react";
import { Cookies } from 'react-cookie';

import { DataUtility } from "@utils/DataUtility";
import { DateHelper } from "@utils/DateHelper"
import { configureDisplayMessage } from "@utils/DisplayMessageUtility";
import { getLanguage, getLocalizationFileUrl } from "@utils/LanguageUtils";
import { LocalStorage, StorageKeys } from "@utils/LocalStorage";
import { Logger } from "@utils/Logger";
import { getLoggedInUserId, getQueryParams, getUserRole } from "@utils/UserUtils";

import { CourseModel, ILMSSession } from "@common/domain/models/Course";
import { ProfileModel } from "@common/domain/models/Profile";
import { FileRepository } from "@common/domain/repositories/FileRepository";
import { clearNetworkCacheInvalidator, resetNetworkLayerCache, setNetworkCacheInvalidator } from "@common/network/clientCacheHandler";

import { IUserModel } from '@redux/auth/interface';

import { Language } from "@models/Language";

import { WFSpinner } from "@components/index";

import { AnalyticsWebEvents, productTourExpirationDate ,CLIENT_NAME } from "@constants/config";
import { SuperAdminMessageType } from '@constants/config'

import { ADMIN_CONSOLE, DOMAIN, WEB_URL } from '@network/constants';
import WFRouter from "@router/Router";
import { AnalyticsService } from "@webServices/AnalyticsService";
import { CRMService } from '@webServices/CRMService';
import { LanguageService } from "@webServices/LanguageService";
import { SentryService } from "@webServices/SentryService";
import { WebNotificationService } from "@webServices/WebNotificationService";
import ProfileCompletionModal from "./ProfileCompletionModal";
import { IFetchCourseDetailFromCourseKey } from "@common/redux/course/actions";


interface IProps {
  isAuthenticated: boolean;
  isWebView: boolean;
  setWebView: () => void;
  userProfile?: ProfileModel;
  getAppData: () => void;
  appDataLoading: boolean;
  languages: Language[];
  fetchLanguages: () => void;
  languagesLoading: boolean;
  logout: (installationId: string) => void;
  superAdminSignIn: (data: IUserModel) => void;
  getLogoutLoaderSelector: boolean;
  fetchLmsSession: () => void;
  LmsSession: ILMSSession;
  isFetchingLmsSession: boolean;
  getCourseDetailFromCourseKey: (payload: IFetchCourseDetailFromCourseKey) => void;
  coursesDetailFromCourseKeyLoader: boolean;
  coursesDetailFromCourseKeySelector: CourseModel;
  setAuthenticatedFlag: (flag: boolean) => void;
}

interface IState {
  preparing: boolean;
  parentWindow: Window;
}

// set up cookies
const cookies = new Cookies();
class Root extends React.Component<IProps, IState> {
  state = {
    preparing: true,
    parentWindow: null,
  };

  private fetchCourseDetailFromCourseKey = () => {
    const { getCourseDetailFromCourseKey } = this.props;
    const courseKey = getQueryParams('courseKey') || localStorage.getItem(StorageKeys.COURSE_KEY);
    const courseRootId = getQueryParams('courseRootId');
    if(courseKey || courseRootId){
      const payload = {
        userId: getLoggedInUserId(),
        ...(courseKey && {courseKey}),
        ...(courseRootId && {courseRootId})
      };
      getCourseDetailFromCourseKey(payload);
    }
  }

  public async componentDidMount() {
    const { isAuthenticated, getAppData, fetchLanguages } = this.props;
    
    // Global Configuration for display Messages
    configureDisplayMessage();
    if (!isAuthenticated) {
      fetchLanguages();
    }
    if (isAuthenticated) {
      CRMService.doOperation("do", 'chat:hide');
      WebNotificationService.initiateNotification();
      getAppData();
      this.fetchCourseDetailFromCourseKey();
    }
    this.setState({ preparing: false });
    await this.checkWebView();

    // To trigger a message listener when opened in an Iframe within Admin console.
    this.startListeningToMessage();
    resetNetworkLayerCache();
    setNetworkCacheInvalidator();
  }


  public componentWillUnmount(): void {
    clearNetworkCacheInvalidator()
    this.stopListeningToMessage();
  }

  private getLocalisationFileUrl = () => {
    const localizationFileUrl = getLocalizationFileUrl(getLanguage());
    return localizationFileUrl;
  }

  private userLanguage = async () => {
    const {
      languages,
      userProfile,
    } = this.props;
    const accessToken = localStorage.getItem(
      StorageKeys.ACCESS_TOKEN
    );
    if (!accessToken) {
      if (languages && languages.length) {
        // get guest user lang
        await LanguageService.storeGuestUserLanguage();
      } else {
        await LanguageService.setDefaultLanguage();
      }
    }

    if (accessToken && userProfile && !DataUtility.isEmpty(userProfile)) {
      const userPreferredLang = userProfile.getPreferredLanguage();
      localStorage.setItem(StorageKeys.USER_NAME, userProfile?.getName());
      LanguageService.changeLanguage(userPreferredLang);
      const localizationFileUrl = this.getLocalisationFileUrl();
      FileRepository.getLanguageJson(localizationFileUrl, getLanguage());
    }
  };

  private checkWebView = async () => {
    const isWebView = await LocalStorage.get<boolean>(StorageKeys.IS_WEBVIEW);
    const accessToken = await LocalStorage.get<string>(
      StorageKeys.ACCESS_TOKEN
    );

    if (!!isWebView && !!accessToken) {
      this.props.setWebView();
      const localizationFileUrl = this.getLocalisationFileUrl();
      FileRepository.getLanguageJson(localizationFileUrl, getLanguage());
    }
  };

  private unsetServicesData = () => {
    // unset userId for Analytics
    AnalyticsService.setUserId(null);
    this.unsetUserIdForSentry();
    //unset CRM attributes for user when he logs out
    // CRMService.doOperation("do", "session:reset");
    // CRMService.setAttributes("set", {"session:segments": CLIENT_NAME});
  };

  private setServicesData = async (profile: ProfileModel) => {
    const uuid = profile && profile.getProfileUuid && profile.getProfileUuid();
    if (uuid) {
      AnalyticsService.setUserId(uuid);
      this.setUserIdForSentry(uuid);
    }
    const userRole = getUserRole();
    if (userRole) {
      const userProps = {
        [`${AnalyticsWebEvents.USER_TYPE}`]: userRole
      };
      AnalyticsService.setUserProperties(userProps);
    }

    this.props.fetchLmsSession();
    // checking the sessoin key every 20 mins
    // if (profile) {
    //       //Set CRM attributes for logged in user
    //       // Called if crisp script is already loaded
    //       // @ts-ignore
    //   window.CRISP_TOKEN_ID = uuid;
    //   CRMService.doOperation("do", 'chat:hide');
    //   CRMService.setAttributes("set", { "user:nickname": profile?.getRole() });
    // }
  };

  private logOut = async () => {
    // unregister FCM
    const uuid = await LocalStorage.get<string>(StorageKeys.USER_UUID);
    // remove all notification cards
    WebNotificationService.closeAllNotifications();
    this.props.logout(uuid || '');
  };

  private startListeningToMessage = () => {
    window.addEventListener("message", this.receiveMessage);
  }

  private stopListeningToMessage = () => {
    window.removeEventListener('message', this.receiveMessage);
  }

  private setUserIdForSentry(userId: string) {
    SentryService.setUserContextOnLogin(userId);
  }

  private unsetUserIdForSentry() {
    SentryService.removeUserContextOnLogout();
  }

  private receiveMessage = async (e: any) => {
    try {
      const payload = e && e.data && JSON.parse(e.data);
      const parent = window.parent;
      this.setState({ parentWindow: parent })
      switch (payload.method) {
        case SuperAdminMessageType.SET:
          parent?.postMessage(JSON.stringify({ method: SuperAdminMessageType.FIRST_ACKNOWLEDGEMENT }), ADMIN_CONSOLE)
          break;
        case SuperAdminMessageType.SET_CONFIRM:
          await LocalStorage.set<boolean>(StorageKeys.IS_SUPER_ADMIN, payload.data.UserSession);
          this.props.superAdminSignIn(payload.data.user);
          break;
        case SuperAdminMessageType.DELETE:
          this.logOut();
          break;
      }
    } catch (e) {
      return;
    }
  }

  public async componentDidUpdate(prevProps: IProps) {
    const {
      userProfile,
      isAuthenticated,
      appDataLoading,
      getAppData,
      languagesLoading,
      getLogoutLoaderSelector,
      LmsSession,
      isFetchingLmsSession,
      coursesDetailFromCourseKeyLoader,
      coursesDetailFromCourseKeySelector,
      setAuthenticatedFlag
    } = this.props;
    if (isAuthenticated && !prevProps.isAuthenticated) {
      WebNotificationService.initiateNotification();
      getAppData();
      this.fetchCourseDetailFromCourseKey();
    } else if (!this.props.isAuthenticated && prevProps.isAuthenticated) {
      // Delete registered FCM token
      WebNotificationService.deleteToken();
      const localizationFileUrl = this.getLocalisationFileUrl();
      FileRepository.getLanguageJson(localizationFileUrl, getLanguage());
      this.unsetServicesData();
      Logger.info(
        "[Root - componentDidUpdate] User logged out, get guest user localisation file"
      );
    }

    if (prevProps.appDataLoading && !appDataLoading && isAuthenticated) {
      this.setState({ preparing: false });
      await this.userLanguage();
      if (userProfile) {
        this.setServicesData(userProfile);
      }
    }

    if(prevProps.languagesLoading && !languagesLoading && !isAuthenticated) {
        this.setState({ preparing: false });
        await this.userLanguage();
    };

    if(prevProps.getLogoutLoaderSelector && !getLogoutLoaderSelector && this.state?.parentWindow && this.state?.parentWindow?.postMessage) {
      this.state?.parentWindow?.postMessage(JSON.stringify({method: SuperAdminMessageType.CLOSE_MODAL}), ADMIN_CONSOLE);
    }
    if(prevProps.isFetchingLmsSession && !isFetchingLmsSession && LmsSession){
      const expireDate = DateHelper.getDateTimeFromUnixEpochTime(LmsSession?.expiry)
      cookies?.set('MoodleSession', LmsSession?.session, { path: '/', domain: '.wfglobal.org', expires: new Date(LmsSession?.expiry * 1000) },);
      cookies?.set('expires', LmsSession?.expiry, { path: '/', domain: '.wfglobal.org', },);
    }

    const courseKey = getQueryParams('courseKey') || localStorage.getItem(StorageKeys.COURSE_KEY);
    if(isAuthenticated && !coursesDetailFromCourseKeyLoader && !coursesDetailFromCourseKeySelector && courseKey){
      this.fetchCourseDetailFromCourseKey();
    }
    const ssoToken = getQueryParams('token');
    if(ssoToken && isAuthenticated) {
      setAuthenticatedFlag(false);
    }
  }

  public render() {
    const { preparing } = this.state;
    const { coursesDetailFromCourseKeyLoader, isAuthenticated, coursesDetailFromCourseKeySelector } = this.props;
    const courseKey = getQueryParams('courseKey') || localStorage.getItem(StorageKeys.COURSE_KEY);
    const ssoToken = getQueryParams('token');
    if(coursesDetailFromCourseKeyLoader || (courseKey && isAuthenticated && !coursesDetailFromCourseKeyLoader && !coursesDetailFromCourseKeySelector) || (ssoToken && isAuthenticated)){
      return <WFSpinner  showFullPage={true} size={"large"}/>;
    }
    return (
      <React.Fragment>
        <WFRouter />
        <ProfileCompletionModal />
      </React.Fragment>
    );


    return (
      <React.Fragment>
        {preparing ? (
          <WFSpinner showFullPage={true} size={"large"} />
        ) : (
          <WFRouter />
        )}
      </React.Fragment>
    );
  }
}

export default Root;