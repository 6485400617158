export const customText = {
    jobRoles: "job-roles",
    fetchAllJobRoles: "fetchAllJobRoles",
    status: "VERIFIED",
    aiCoach: 'aiCoach',
    philippinesLangauge: "philippines",
    interview: 'interview',
    generalQuestions: 'GeneralQuestions',
    interviewQuestions: 'interviewQuestions',
    review: 'review',
    india: 'india',
    philippines: 'philippines',
    stop: 'STOP',
    start: 'START',
    initialState: 'initialState',
    loading: 'loading',
    loaded: 'loaded',
    deleted: 'DELETED',
    PERMISSION_DENIED: 'Permission denied',
    bangladesh: 'bangladesh',
    aiCoachId: 'ai-coach'
};

export const countries = {
    philippines: 'philippines',
    algeria: 'algeria',
    angola: 'angola',
    benin: 'benin',
    botswana: 'botswana',
    caboVerde: "cabo verde",
    cameroon: "cameroon",
    centralAfricanRepublic: "central african republic",
    chad: "chad",
    comoros: "comoros",
    congoKinshasa: "congo (kinshasa)",
    congoBrazzaville: "congo (brazzaville)",
    djibouti: "djibouti",
    egypt: "egypt",
    equatorialGuinea: "equatorial guinea",
    eritrea: "eritrea",
    swaziland: "swaziland",
    theGambia: "the gambia",
    guinea: "guinea",
    guineaBissau: "guinea-bissau",
    ghana: "ghana",
    kenya: "kenya",
    lesotho: "lesotho",
    libya: "libya",
    madagascar: "madagascar",
    malawi: "malawi",
    mali: "mali",
    mauritania: "mauritania",
    mauritius: "mauritius",
    morocco: "morocco",
    mozambique: "mozambique",
    namibia: "namibia",
    niger: "niger",
    nigeria: "nigeria",
    rwanda: "rwanda",
    saoTomeAndPrincipe: "sao tome and principe",
    senegal: "senegal",
    seychelles: "seychelles",
    sierraLeone: "sierra leone",
    somalia: "somalia",
    southAfrica: "south africa",
    southSudan: "south sudan",
    sudan: "sudan",
    tanzania: "tanzania",
    togo: "togo",
    tunisia: "tunisia",
    uganda: "uganda",
    zambia: "zambia",
    zimbabwe: "zimbabwe"
};

export enum ExperienceType {
    Fresher = 'FRESHER',
    Experience = 'EXPERIENCED'
};

export enum selectedType {
    input = 'input',
    suggestions = 'suggestions',
    yes = 'Yes',
    jobRoleBlocked = 'JOB_ROLE_BLOCKED',
    notValid = 'not valid',
    blocked = 'Blocked'
}

export const overAllRating = ['Overall Rating', 'Valoración Global', 'Classificação geral', 'Avaliação geral', 'Avaliação Geral', 'Classificação Geral', 'Valoración general', 'Calificación general', 'Valoración General', 'Puntuación General', 'Puntuación Total', 'Calificación General', 'Overall rating', 'Valoración global', 'समग्र रेटिंग', 'التقييم الإجمالي'];

export const placementLanguagesId = {
    English: 'en',
    Español: 'es',
    Português: 'pt'
};

export const groupName = {
    OPEN_TEXT : "openText"
}

export const sourceFormatType = {
    SHORT_TEXT: 'shortText',
    LONG_TEXT: 'longText',
}
